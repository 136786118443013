<template>
  <div>
    <v-combobox
      class="my-2"
      v-model="keyword"
      :search-input.sync="search"
      placeholder="ชื่อสะพาน หรือเลข กม."
      solo
      prepend-inner-icon="mdi-magnify"
      dense
      hide-details
      append-icon=""
      :items="bridgeSuggest"
      item-text="name"
      item-value="name"
      clearable
      :loading="isSuggestLoading"
      :filter="filterBridge"
      @keydown.enter="keydownSuggest"
      @input="inputSuggest"
    >
    </v-combobox>
  </div>
</template>

<script>
export default {
  data: () => ({
    search: null,
    isSuggestLoading: false,
    bridgeSuggest: [],
    keyword: null
  }),
  watch: {
    search (val) {
      clearTimeout(this.searchKeywordTimeout)
      if (!this.search) {
        this.isSuggestLoading = false
        this.bridgeSuggest = []
        clearTimeout(this.searchKeywordTimeout)
        this.search = null
        return
      }
      if (val.length < 2) {
        this.isSuggestLoading = false
        this.bridgeSuggest = []
        return
      }
      this.isSuggestLoading = true
      this.searchKeywordTimeout = setTimeout(() => {
        this.isSuggestLoading = false
        this.getBridgeSuggest()
      }, 1000)
    }
  },
  methods: {
    filterBridge (item, queryText, itemText) {
      return (
        (itemText.includes(queryText) && queryText !== undefined) ||
        item.header ||
        item.id.toString().includes(queryText)
      )
    },
    inputSuggest () {
      if (typeof this.keyword === 'object' && this.keyword !== null) {
        this.$router.push({ name: 'Bridge', params: { keyword: this.keyword.name || this.keyword } })
      }
    },
    async getBridgeSuggest () {
      const bridgeSuggest = []
      if (this.search) {
        const bridgeAll = await this.$store.dispatch('getBridgeList', {
          keyword: this.search
        })
        for (const section of bridgeAll) {
          bridgeSuggest.push({ header: section.name })
          for (const bridge of section.items) {
            bridgeSuggest.push(bridge)
          }
        }
      }
      this.bridgeSuggest = bridgeSuggest
    },
    keydownSuggest () {
      this.keyword = this.search
      this.$router.push({ name: 'Bridge', params: { keyword: this.keyword.name || this.keyword } })
    }
  }
}
</script>
