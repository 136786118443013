<template>
  <div class="primary-light pa-4 d-sm-flex align-end">
    <v-row>
      <v-col
        v-for="(form, indexform) in filterField"
        :key="indexform"
        cols="12"
        sm="6"
      >
        <v-row align="center">
          <v-col cols="5" style="font-size: 14px">
            {{ form.name }}
          </v-col>
          <v-col cols="7">
            <v-select
              v-if="form.model === 'road'"
              v-model="filterForm[form.model]"
              :items="itemLists[form.select.name]"
              :item-text="form.select['item-text']"
              :item-value="form.select['item-value']"
              placeholder="เลือก"
              clearable
              outlined
              dense
              hide-details
              :loading="isLoadingRoad"
              :no-data-text="form.nodata"
              background-color="white"
            >
            </v-select>
            <v-select
              v-else-if="form.model === 'section_part_id'"
              v-model="filterForm[form.model]"
              :items="itemLists[form.select.name]"
              :item-text="form.select['item-text']"
              :item-value="'section_part_id'"
              :no-data-text="form.nodata"
              placeholder="เลือก"
              clearable
              outlined
              dense
              hide-details
              :loading="isLoadingSectionPart"
              background-color="white"
            ></v-select>
            <v-select
              v-else-if="form.type === 'select'"
              v-model="filterForm[form.model]"
              :items="itemLists[form.select.name]"
              :item-text="form.select['item-text']"
              :item-value="form.select['item-value']"
              :clearable="form.model !== 'budgetYear'"
              :no-data-text="form.nodata"
              placeholder="เลือก"
              outlined
              dense
              hide-details
              background-color="white"
            ></v-select>
            <v-autocomplete
              v-else-if="form.type === 'autocomplete'"
              v-model="filterForm[form.model]"
              outlined
              dense
              clearable
              placeholder="เลือก"
              background-color="white"
              hide-details
              :items="itemLists[form.autocomplete.name]"
              :item-text="form.autocomplete['item-text']"
              :item-value="form.autocomplete['item-value']"
              :no-data-text="form.nodata"
            >
              <!-- <template v-slot:item="{ item }">
                <div>
                  {{ item[form.autocomplete.text] }}
                </div>
              </template>
              <template v-slot:selection="{ item, index }">
                <div v-if="index === 0">
                  <span>{{ item[form.autocomplete.text] }} </span>
                </div>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filter[form.model].length - 1 }} รายการ)
                </span>
              </template> -->
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      class="d-flex justify-end"
      :class="$vuetify.breakpoint.xs ? 'mt-4' : ''"
    >
      <v-btn color="primary" @click="filterSearch">ค้นหา</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    filterField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budgetYear',
        type: 'select',
        nodata: 'ไม่มีปีงบประมาณ',
        select: {
          name: 'budgetYear'
        }
      },
      {
        name: 'หมวดทางหลวง',
        model: 'depot_code',
        type: 'select',
        nodata: 'ไม่มีหมวดทางหลวงให้เลือก',
        select: {
          name: 'v-mbmms-depot',
          'item-text': 'name',
          'item-value': 'depot_code'
        }
      },

      {
        name: 'หมายเลขทางหลวง',
        model: 'road',
        type: 'select',
        select: {
          name: 'road',
          'item-text': 'label',
          'item-value': 'road_id'
        },
        nodata: 'ไม่มีหมายเลขทางหลวงให้เลือก'
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        type: 'select',
        select: {
          name: 'section_part_id',
          'item-text': 'text',
          'item-value': 'section_part_id'
        },
        nodata: 'โปรดเลือกหมายเลขทางหลวง'
      },
      {
        name: 'จังหวัด',
        model: 'province_code',
        type: 'autocomplete',
        nodata: 'ไม่มีจังหวัดให้เลือก',
        autocomplete: {
          name: 'v-mbmms-province',
          'item-text': 'name_th',
          'item-value': 'province_code'
        }
      }
    ],
    itemLists: {},
    filterForm: {},
    sectionPartList: [],
    isLoadingRoad: false,
    isLoadingSectionPart: false
  }),
  watch: {
    'filterForm.road' (newVal, oldVal) {
      if (newVal) {
        delete this.filterForm.section_part_id
        this.getRefSectionPart(newVal)
      } else {
        this.itemLists.section_part_id = []
      }
    },
    'filterForm.depot_code' (newVal, oldVal) {
      this.getRefRoad()
    }
  },
  async mounted () {
    // await this.getRef('province')
    await this.getRef('v-mbmms-depot')
    await this.getRef('v-mbmms-province')
    await this.getRefRoad()
    await this.getRefDepot()
    await this.getBudgetYearList()
    this.$emit('filter', this.filterForm)
  },
  methods: {
    async getBudgetYearList () {
      const budgetYear = await this.$store.dispatch('getBudgetYearList')
      this.filterForm.budgetYear = budgetYear[0]
      this.$set(this.itemLists, 'budgetYear', budgetYear)
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefRoad () {
      this.isLoadingRoad = true
      const filter = {
        depot_code: this.filterForm.depot_code
      }
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'road',
        filter
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = roads.some((id) => id.road_id === this.filterForm.road)
      if (!checkid) {
        delete this.filterForm.road
        delete this.filterForm.section_part_id
        this.itemLists.section_part_id = []
      }
      this.itemLists.road = roads
      this.isLoadingRoad = false
    },
    async getRefSectionPart (roadId) {
      this.isLoadingSectionPart = true
      const sectionPart = await this.$store.dispatch('ref/getRef', {
        method: `section_part/${roadId}`
      })
      for (const section of sectionPart) {
        section.text = `${section.code} - ${section.name}`
      }
      this.itemLists.section_part_id = sectionPart
      this.isLoadingSectionPart = false
    },
    async getRefDepot () {
      this.itemLists.depot = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-depot'
      })
    },
    filterSearch () {
      this.$emit('filter', { ...this.filterForm })
    }
  }
}
</script>

<style></style>
