<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-transition"
      max-width="600"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            ผลการค้นหา
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-2 primary">
            {{ bridgeList.length }} สะพาน
          </v-card-subtitle>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table class="elevation-2 mt-4">
            <template v-slot:default>
              <thead class="primary">
                <tr>
                  <th class="text-center white--text rounded-tl">กม.</th>
                  <th class="text-center white--text">ชื่อสะพาน</th>
                  <th class="text-center white--text text-no-wrap">
                    ความยาวสะพาน (ม.)
                  </th>
                  <th
                    v-if="hasSr"
                    class="text-center white--text rounded-tr text-no-wrap"
                  >
                    SR
                  </th>
                </tr>
              </thead>
              <tr v-if="isLoading" class="pa-0 ma-0">
                <td colspan="4" class="pa-0 ma-0">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tbody>
                <tr
                  v-for="item in bridgeList"
                  :key="item.bridge_id"
                  @click="gotoBridgePage(item)"
                  class="pointer-hover"
                >
                  <td>{{ item.km ? getKm(item.km) : '-' }}</td>
                  <td>
                    {{ item.bridge_name
                    }}{{ item.direction ? ` (${item.direction})` : '' }}
                  </td>
                  <td class="text-center">{{ item.length }}</td>
                  <td v-if="hasSr" class="text-center text-no-wrap">
                    {{
                      item.sr ? `${parseFloat(item.sr || 0).toFixed(2)} %` : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  components: {},
  props: {
    value: Boolean,
    bridgeList: Array,
    isLoading: {
      type: Boolean,
      default: false
    },
    hasSr: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.dialog = newVal
    }
  },
  data: () => ({
    dialog: false,
    editForm: {},
    isConfirmEditDialog: false,
    getKm: utils.getKm
  }),
  methods: {
    close () {
      this.$emit('close')
    },
    gotoBridgePage (bridge) {
      this.$router.push({
        name: 'Damage',
        params: { keyword: bridge.bridge_name }
      })
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
