<template>
  <div>
    <h3 class="text-center mb-4 font-weight-regular">S.R.</h3>
    <div v-show="showchart" :id="id"></div>
    <div v-show="!showchart" class="grey--text text-center">ไม่มีข้อมูล</div>
    <BridgeListResultDialog
      v-model="dialog"
      :bridgeList="bridgeList"
      :isLoading="isLoading"
      hasSr
      @close="dialog = false"
    />
  </div>
</template>

<script>
// import Highcharts from 'highcharts'
import BridgeListResultDialog from '@/components/homepage/dialog/BridgeListResultDialog.vue'
var Highcharts = require('highcharts')
require('highcharts/modules/pattern-fill')(Highcharts)

export default {
  name: 'srchart',
  props: {
    id: String,
    filter: Object
  },
  components: {
    BridgeListResultDialog
  },
  // mounted () {
  //   this.getCountPrincipalGroupBySr()
  // },
  data () {
    return {
      colors: ['#FD5B5D', '#FEE433', '#1DBBA0'],
      series: [],
      budgetYear: null,
      data: [],
      bridgeList: [],
      showchart: false,
      dialog: false,
      isLoading: false
    }
  },
  computed: {},
  methods: {
    async getCountPrincipalGroupBySr () {
      const filter = { ...this.filter }
      this.budgetYear = filter.budgetYear
      const data = await this.$store.dispatch('getCountPrincipalGroupBySr', {
        budgetYear: this.budgetYear,
        filter
      })
      if (data.length > 0) {
        data.forEach((d, i) => {
          const ref = d.ref || '7-emergency'
          const [bridge, type] = ref.split('-')
          let stroke = ''
          switch (type.toLowerCase()) {
            case 'emergency':
              stroke = this.colors[0]
              break
            case 'indepth':
              stroke = this.colors[1]
              break
            case 'routine':
              stroke = this.colors[2]
              break
          }
          if (bridge === '7') {
            d.color = stroke
          } else {
            d.color = {
              pattern: { ...Highcharts.patterns[0], color: stroke }
            }
          }
        })
        this.data = data
        this.showchart = true
        this.initChart()
      } else {
        this.showchart = false
      }
    },

    async getBridgeListByPrincipalGroupBySrRange (roadCode, range) {
      this.bridgeList = []
      this.dialog = true
      this.isLoading = true
      const filter = { ...this.filter, road_code: roadCode, range }
      this.budgetYear = filter.budgetYear
      const data = await this.$store.dispatch(
        'getBridgeListByPrincipalGroupBySrRange',
        {
          budgetYear: this.budgetYear,
          filter
        }
      )
      this.bridgeList = data
      this.isLoading = false
    },

    initChart () {
      const legendOption =
        this.$vuetify.breakpoint.width > 700
          ? { width: 600, itemWidth: 300 }
          : { layout: 'vertical' }
      Highcharts.chart(this.id, {
        chart: {
          type: 'column'
        },
        title: {
          text: '',
          style: {
            fontFamily: 'Roboto'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            },
            cursor: 'pointer',
            point: {
              events: {
                click: ({ point }) => {
                  const data = this.data.find(
                    (e) => e.name === point.series.legendItem.textStr
                  )
                  this.getBridgeListByPrincipalGroupBySrRange(
                    data.ref.split('-')[0].padStart(4, '0'),
                    point.x
                  )
                  // console.log('Category: ' + this.category + ', value: ' + this.y)
                }
              }
            }
          },
          column: {
            pointPadding: 0.05,
            groupPadding: 0,
            stacking: 'normal'
          }
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontFamily: 'Roboto, sans-serif'
            }
          }
        },
        legend: {
          ...legendOption,
          itemMarginTop: 5,
          itemStyle: {
            fontWeight: 'Regular',
            fontFamily: 'Roboto'
          }
        },
        lang: {
          noData: 'ไม่มีข้อมูล'
        },
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
          }
        },
        yAxis: {
          title: {
            text: 'จำนวนสะพาน (ตัว)'
          },
          stackLabels: {
            enabled: true,
            style: {
              fontFamily: 'Roboto'
            }
          }
        },
        series: this.data
      })
    }
  }
}
</script>

<style></style>
