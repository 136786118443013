<template>
  <div>
    <v-card
      class="text-center"
      :class="color"
      :style="`border: 2px solid ${color}; background: #FAFAFA`"
      outlined
    >
      <h3
        class="white--text font-weight-regular"
        :style="`background-color: ${color}; border-radius:0px`"
      >
        {{ title || '&nbsp;' }}
        <v-progress-linear
          v-if="isloading"
          color="grey lighten-2"
          indeterminate
        ></v-progress-linear>
        <v-tooltip v-if="tooltip" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              v-bind="attrs"
              v-on="on"
              style="position: absolute; right: 0"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ title }} {{ tooltip }}</span>
        </v-tooltip>
      </h3>
      <h1 class="my-2">{{ data }}</h1>
      <div>สะพาน</div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    title: String,
    amount: Number,
    isloading: Boolean,
    tooltip: String
  },
  watch: {
    amount (val) {
      clearTimeout(this.dataTimeout)
      this.data = 0
      this.setCount(0, val)
    }
  },
  data: () => ({
    data: 0,
    speed: 5,
    dataTimeout: null
  }),
  methods: {
    setCount (i, endNbr) {
      if (i <= endNbr) {
        this.data = i
        this.dataTimeout = setTimeout(() => {
          this.setCount(i + 1, endNbr)
        }, this.speed)
      }
    }
  }
}
</script>

<style></style>
