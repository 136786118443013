<template>
  <v-card class="pa-2 fill-height" outlined>
    <h3 class="text-center mb-4 font-weight-regular">
      จำนวนสะพานที่ตรวจสอบ Routine
    </h3>
    <div v-show="showchart" :id="id"></div>
    <div v-show="!showchart" class="grey--text text-center">ไม่มีข้อมูล</div>
    <BridgeListResultDialog
      v-model="dialog"
      :bridgeList="bridgeList"
      :isLoading="isLoading"
      @close="dialog = false"
    />
  </v-card>
</template>

<script>
import Highcharts from 'highcharts'
import BridgeListResultDialog from '@/components/homepage/dialog/BridgeListResultDialog.vue'
export default {
  components: {
    BridgeListResultDialog
  },
  props: {
    id: String,
    filter: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      colors: ['#FD5B5D', '#FEE433', '#1DBBA0'],
      series: [],
      budgetYear: null,
      data: [],
      showchart: false,
      dialog: false,
      bridgeList: [],
      isLoading: false
    }
  },
  async mounted () {
    // await this.getCountSurveyPrincipal()
  },
  methods: {
    async getCountSurveyRoutine () {
      const filter = { ...this.filter }
      this.budgetYear = filter.budgetYear
      delete filter.budgetYear
      this.data = []
      const data = await this.$store.dispatch('getCountSurveyRoutine', {
        budgetYear: this.budgetYear,
        filter
      })
      if (data.length > 0) {
        this.showchart = true
        data.forEach((item) => {
          switch (item.name) {
            case 'ยังไม่ตรวจสอบ':
              item.color = '#757575'
              break
            case 'ตรวจสอบแล้ว':
              item.color = '#C02C46'
              break
          }
        })
        if (data.length === 2) {
          const temp = data[0]
          data[0] = data[1]
          data[1] = temp
        }
        this.data = data
        this.initChart()
      } else {
        this.showchart = false
      }
    },

    async getBridgeListCountSurveyRoutine (roadCode, isSurvey) {
      this.dialog = true
      this.isLoading = true
      this.bridgeList = []
      const filter = {
        ...this.filter,
        road_code: roadCode,
        is_survey: isSurvey
      }
      this.budgetYear = filter.budgetYear
      const data = await this.$store.dispatch(
        'getBridgeListCountSurveyRoutine',
        {
          budgetYear: this.budgetYear,
          filter
        }
      )
      this.bridgeList = data
      this.isLoading = false
    },

    initChart () {
      Highcharts.chart(this.id, {
        chart: {
          type: 'column'
        },
        title: {
          text: '',
          style: {
            fontFamily: 'Roboto'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y} สะพาน'
            },
            cursor: 'pointer',
            point: {
              events: {
                click: ({ point }) => {
                  const data = this.data.find(
                    (e) => e.name === point.series.legendItem.textStr
                  )
                  const isSurvey = data.name === 'ตรวจสอบแล้ว' ? 1 : 0
                  const roadCode = data.data[point.x].name
                    .split('.')[1]
                    .padStart(4, '0')
                  this.getBridgeListCountSurveyRoutine(roadCode, isSurvey)
                }
              }
            }
          }
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontFamily: 'Roboto, sans-serif'
            }
          }
        },
        legend: {
          layout: 'vertical',
          itemMarginTop: 5,
          itemStyle: {
            fontWeight: 'Regular',
            fontFamily: 'Roboto'
          }
        },
        yAxis: {
          title: {
            text: 'จำนวนสะพาน (ตัว)'
          },
          stackLabels: {
            enabled: true,
            style: {
              fontFamily: 'Roboto'
            }
          }
        },
        series: this.data
      })
    }
  }
}
</script>

<style></style>
