import { render, staticRenderFns } from "./BridgeListResultDialog.vue?vue&type=template&id=2c478155&scoped=true&"
import script from "./BridgeListResultDialog.vue?vue&type=script&lang=js&"
export * from "./BridgeListResultDialog.vue?vue&type=script&lang=js&"
import style0 from "./BridgeListResultDialog.vue?vue&type=style&index=0&id=2c478155&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c478155",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VDialog,VDivider,VIcon,VProgressLinear,VSimpleTable})
