<template>
  <div class="fill-height pa-0 ma-0">
    <v-container
      v-if="$vuetify.breakpoint.smAndUp"
      class="fill-height d-none d-sm-flex"
      fluid
    >
      <v-layout class="split" fill-height>
        <v-col class="pa-0 overflow-y-auto" style="flex: 1 1 66.666666667%">
          <div class="pa-4 primary">
            <SearchBridgeField />
          </div>
          <div>
            <FilterHomepage @filter="filterSearch" />
          </div>
          <div class="pa-4">
            <v-row class="d-flex">
              <v-col class="d-flex" cols="5" sm="12" md="5">
                <div class="flex flex-column flex-grow-1">
                  <WidgetSummary ref="widgetSummary" :filter="filter" />
                </div>
              </v-col>
              <v-col cols="7" sm="12" md="7">
                <CountSurveyPrincipalChart
                  ref="countServeyPrincipal"
                  :id="'count-survey-principal-chart-normal'"
                  :filter="filter"
                />
              </v-col>
            </v-row>
            <v-card class="pa-2 mt-4" outlined>
              <SrChart
                ref="srchartnormal"
                :filter="filter"
                :id="'sr-chart-normal'"
              />
              <!-- <SrTable ref="srTable" :filter="filter" /> -->
            </v-card>
            <CountSurveyRoutineChart
              class="mt-4"
              ref="countServeyRoutine"
              :id="'count-survey-routine-chart-normal'"
              :filter="filter"
            />
          </div>
        </v-col>
        <!--        <v-col cols="4" class="pa-4 right overflow-y-auto pa-0">-->
        <!--          <v-card class="primary text-center white&#45;&#45;text pa-2">-->
        <!--            ข่าวประกาศ-->
        <!--          </v-card>-->
        <!--          <AnnounceListItem-->
        <!--            v-for="(item, index) in announceLists"-->
        <!--            :key="index"-->
        <!--            class="mt-4"-->
        <!--            :title="item.title"-->
        <!--            :createDate="item.createDate"-->
        <!--            :createOwner="item.createOwner"-->
        <!--            :image="item.image"-->
        <!--          />-->
        <!--        </v-col>-->
      </v-layout>
    </v-container>
    <div v-else class="fill-height hidden-sm-and-up ma-0 pa-0">
      <div class="pa-4 primary">
        <SearchBridgeField />
      </div>
      <v-tabs grow v-model="tab">
        <v-tab> สถิติ </v-tab>
        <!-- <v-tab>ข่าวประกาศ</v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <FilterHomepage @filter="filterSearch" />
          <div class="pa-4">
            <v-row class="d-flex">
              <v-col class="d-flex" cols="12">
                <div class="flex flex-column flex-grow-1">
                  <WidgetSummary ref="widgetSummaryMobile" :filter="filter" />
                </div>
              </v-col>
              <v-col cols="12">
                <CountSurveyPrincipalChart
                  ref="countServeyPrincipalMobile"
                  :id="'count-survey-principal-chart-mobile'"
                  :filter="filter"
                />
              </v-col>
            </v-row>
            <v-card class="pa-2 mt-4" outlined>
              <SrChart
                ref="srchartnormalmobile"
                :filter="filter"
                :id="'sr-chart-mobile'"
              />
              <!-- <SrTable ref="srTableMobile" :filter="filter" /> -->
            </v-card>
            <CountSurveyRoutineChart
              class="mt-4"
              ref="countServeyRoutineMobile"
              :id="'count-survey-routine-chart-mobile'"
              :filter="filter"
            />
          </div>
        </v-tab-item>
        <!-- <v-tab-item>
          <AnnounceListItem
            v-for="(item, index) in announceLists"
            :key="index"
            class="mt-4"
            :title="item.title"
            :createDate="item.createDate"
            :createOwner="item.createOwner"
            :image="item.image"
          />
        </v-tab-item> -->
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
// import AnnounceListItem from '../components/AnnounceListItem.vue'
import WidgetSummary from '../components/homepage/WidgetSummary.vue'
import SrChart from '../components/homepage/SrChart.vue'
// import SrTable from '../components/homepage/SrTable.vue'
import SearchBridgeField from '../components/homepage/SearchBridgeField.vue'
import FilterHomepage from '../components/homepage/FilterHomepage.vue'
import CountSurveyPrincipalChart from '../components/homepage/CountSurveyPrincipalChart.vue'
import CountSurveyRoutineChart from '../components/homepage/CountSurveyRoutineChart.vue'
export default {
  name: 'Home',
  components: {
    // AnnounceListItem,
    SrChart,
    // SrTable,
    SearchBridgeField,
    FilterHomepage,
    CountSurveyPrincipalChart,
    WidgetSummary,
    CountSurveyRoutineChart
  },
  data: () => ({
    tab: null,
    announceLists: [
      {
        title:
          'มอเตอร์เวย์สายอีสาน สร้างเร็วกว่าแผน ทยอยเปิดใช้ฟรีปีหน้า ไม่รองานระบบ',
        createDate: '16 ก.ย. 64 09:45 น.',
        createOwner: 'ลำดวน สวนสมร',
        image:
          'https://i.picsum.photos/id/1018/3914/2935.jpg?hmac=3N43cQcvTE8NItexePvXvYBrAoGbRssNMpuvuWlwMKg'
      },
      {
        title:
          '6 ก.ค.นี้ เปิดใช้สะพานยกระดับข้าม มอเตอร์เวย์ เชื่อมศรีนครินทร์-ร่มเกล้า',
        createDate: '1 ส.ค. 64 13:12 น.',
        createOwner: 'ลำดวน สวนสมร',
        image:
          'https://i.picsum.photos/id/1023/3955/2094.jpg?hmac=AW_7mARdoPWuI7sr6SG8t-2fScyyewuNscwMWtQRawU'
      }
    ],
    filter: {}
  }),
  methods: {
    async filterSearch (filter) {
      this.filter = {}
      for (const [key, value] of Object.entries(filter)) {
        await this.$set(this.filter, key, value)
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        this.$refs.widgetSummary.getCountPrincipalGroupByDepot()
        this.$refs.widgetSummary.getCountSurveyPrincipal()
        this.$refs.widgetSummary.getCountBridgeByServiceBridgeUnder()
        this.$refs.countServeyPrincipal.getCountSurveyPrincipal()
        this.$refs.srchartnormal.getCountPrincipalGroupBySr()
        this.$refs.countServeyRoutine.getCountSurveyRoutine()
        // this.$refs.srTable.getCountPrincipalGroupByDepot()
      } else {
        this.$refs.widgetSummaryMobile.getCountPrincipalGroupByDepot()
        this.$refs.widgetSummaryMobile.getCountSurveyPrincipal()
        this.$refs.widgetSummaryMobile.getCountBridgeByServiceBridgeUnder()
        this.$refs.countServeyPrincipalMobile.getCountSurveyPrincipal()
        this.$refs.srchartnormalmobile.getCountPrincipalGroupBySr()
        this.$refs.countServeyRoutineMobile.getCountSurveyRoutine()
        // this.$refs.srTableMobile.getCountPrincipalGroupByDepot()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.right {
  background-color: whitesmoke;
}
.background-main {
  background-color: whitesmoke;
}
</style>
