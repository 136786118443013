<template>
  <div class="fill-height d-flex flex-column">
    <div class="flex-grow-1 d-flex flex-grow-1">
      <v-card
        outlined
        class="flex-grow-1 pa-4 d-flex flex-column"
        style="background: #fafafa"
      >
        <v-row class="flex-grow-1 ma-0 pa-0">
          <v-col
            cols="6"
            md="5"
            class="d-flex flex-column align-self-center pa-0"
          >
            <div class="text-center flex-grow-1">
              <h2 class="primary--text font-weight-regular">จำนวนสะพาน</h2>
              <h1 class="">{{ sumBridgeDisplay }}</h1>
              <div class="mt-auto">สะพาน</div>
            </div>
          </v-col>
          <v-col cols="6" md="7" class="d-flex flex-column align-end pa-0">
            <div
              class="flex-grow-1 d-flex flex-column flex-lg-row justify-end my-2"
              v-for="(bridgelabel, indexcount) in bridgeCount"
              :key="indexcount"
            >
              <span class="primary--text mr-1">
                <b class="mr-1">{{ bridgelabel.label }}</b>
                <v-icon v-if="bridgelabel.id === '1'" color="primary">
                  $NormalBridgeIcon
                </v-icon>
                <v-icon v-else-if="bridgelabel.id === '2'" color="primary">
                  $CrossBridgeIcon
                </v-icon>
                <v-icon v-else-if="bridgelabel.id === '3'" color="primary">
                  $OverpassBridgeIcon
                </v-icon>
                <v-icon v-else-if="bridgelabel.id === '4'" color="primary">
                  $InterChangeBridgeIcon
                </v-icon>
              </span>
              <div class="d-flex justify-end">
                <div>{{ bridgelabel.count }}</div>
                <div class="ml-1">สะพาน</div>
              </div>
            </div>
            <!-- <div class="flex-grow-1 d-flex align-center">
              <span class="primary--text mr-1">
                ต่างระดับ
                <v-icon color="primary">$InterChangeBridgeIcon</v-icon>
              </span>
              <span> 0 สะพาน </span>
            </div>
            <div class="flex-grow-1 d-flex align-center">
              <span class="primary--text mr-1">
                ท่อลอด/ระบายน้ำ
                <v-icon color="primary">$CrossBridgeIcon</v-icon>
              </span>
              <span>0 สะพาน</span>
            </div> -->
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div class="d-flex mt-4">
      <WidgetSrBox
        class="col-6 pl-0 pt-0"
        :isloading="srloading"
        :title="(srData[2] || {}).name"
        color="#20BBA0"
        tooltip="กำหนดงบบำรุงปกติ"
        :amount="sumSr[3] || 0"
      />
      <WidgetSrBox
        class="col-6 pr-0 pt-0"
        :title="(srData[1] || {}).name"
        :isloading="srloading"
        color="#DCC000"
        tooltip="ดำเนินการตรวจสอบกรณีพิเศษ (In-Depth Inspection)"
        :amount="sumSr[2] || 0"
      />
    </div>
    <div class="d-flex">
      <WidgetSrBox
        class="col-6 pl-0 pb-0"
        :isloading="srloading"
        :title="(srData[0] || {}).name"
        color="#FD5B5D"
        tooltip="ดำเนินการตรวจสอบฉุกเฉิน (Emergency Inspection)"
        :amount="sumSr[1] || 0"
      />
      <WidgetSrBox
        class="col-6 pr-0 pb-0"
        :isloading="notyetloading"
        :title="(srData[3] || {}).name"
        color="#A9A9A9"
        :amount="sumSr[0] || 0"
      />
    </div>
  </div>
</template>

<script>
import WidgetSrBox from '../homepage/WidgetSrBox.vue'
export default {
  props: {
    filter: Object
  },
  components: {
    WidgetSrBox
  },
  computed: {
    sumBridge () {
      return this.bridgeCount.reduce((a, b) => a + parseInt(b.count), 0)
    }
  },
  watch: {
    sumBridge (val) {
      console.log(val)
      clearTimeout(this.sumBridgeTimeout)
      this.sumBridgeDisplay = 0
      this.setCount(0, val)
    }
  },
  data: () => ({
    budgetYear: null,
    headers: {},
    sumSr: { 0: 0, 1: 0, 2: 0, 3: 0 },
    srloading: false,
    notyetloading: false,
    srData: {},
    sumBridgeDisplay: 0,
    speed: 5,
    sumBridgeTimeout: null,
    bridgeCount: []
  }),
  mounted () {
    this.getRefFilterSr()
  },
  methods: {
    async getCountPrincipalGroupByDepot () {
      this.srloading = true
      this.headers = {}
      const filter = { ...this.filter }
      this.budgetYear = filter.budgetYear
      const sumSr = { 1: 0, 2: 0, 3: 0 }
      const data = await this.$store.dispatch('getCountPrincipalGroupByDepot', {
        budgetYear: this.budgetYear,
        filter
      })
      for (const depot of data) {
        sumSr[depot.config_range_sr_id] += parseInt(depot.count)
      }
      this.sumSr = { ...this.sumSr, ...sumSr }
      this.srloading = false
    },
    async getCountSurveyPrincipal () {
      this.notyetloading = true
      const filter = { ...this.filter }
      this.budgetYear = filter.budgetYear
      delete filter.budgetYear
      let sumNotyet = 0
      this.sumSr[0] = sumNotyet

      const data = await this.$store.dispatch('getCountSurveyPrincipal', {
        budgetYear: this.budgetYear,
        filter
      })
      if (data.length > 0) {
        const notyet = data.find(
          (principal) => principal.name === 'ยังไม่ตรวจสอบ'
        )
        if (notyet) {
          notyet.data.forEach((principal) => {
            sumNotyet += principal.y
          })
        }
        this.sumSr[0] = sumNotyet
      }
      this.notyetloading = false
    },
    async getRefFilterSr () {
      this.srData = await this.$store.dispatch('getRefFilterSr')
    },
    async getCountBridgeByServiceBridgeUnder () {
      const filter = { ...this.filter }
      const data = await this.$store.dispatch(
        'getCountBridgeByServiceBridgeUnder',
        filter
      )
      console.log(data)
      this.bridgeCount = data
    },
    setCount (i, endNbr) {
      if (i <= endNbr) {
        this.sumBridgeDisplay = i
        this.sumBridgeTimeout = setTimeout(() => {
          this.setCount(i + 1, endNbr)
        }, this.speed)
      }
    }
  }
}
</script>

<style></style>
